import '@/plugins/composition-api'

import Konva from "konva"
import type { TemplateItemType } from './editor'
import useEditorData from './editorData'
import useEditorFonts from './editorFonts'

export default function () {
  const { editorImages } = useEditorData()
  const { defaultFont } = useEditorFonts()

  const sceneWidth = 1000
  const sceneHeight = 1000
  const scaleBy = 1.1

  const GUIDELINE_OFFSET = 10

  const templateItemShapes: {
    [key in TemplateItemType]: typeof Konva.Shape | typeof Konva.Image
  } = {
    'Text': Konva.Text,
    'Rect': Konva.Rect,
    'Circle': Konva.Circle,
    'Image': Konva.Image,

    'ParticipantImage': Konva.Image,
    'ParticipantQR': Konva.Image,
    'ParticipantDegree': Konva.Text,
    'ParticipantName': Konva.Text,
    'ParticipantFirstName': Konva.Text,
    'ParticipantLastName': Konva.Text,
    'ParticipantGroup': Konva.Text,
    'ParticipantInstitution': Konva.Text,
    'ParticipantLicenseNumber': Konva.Text,
    'ParticipantPoints': Konva.Text
  }

  const defaultConfig: { [key in TemplateItemType | 'GLOBAL']: Konva.ShapeConfig | Konva.ImageConfig | Konva.TextConfig } = {
    'GLOBAL': { x: 100, y: 100, fill: '#9ca3af', rotation: 0, cornerRadius: 0, draggable: true },

    'Text': { text: 'Est eiusmod sint Lorem cupidatat quis ullamco quis.', fill: '#000000', fontSize: 24, lineHeight: 1.5, align: 'left', fontFamily: defaultFont },
    'Rect': { width: 100, height: 100 },
    'Circle': { width: 100, height: 100 },
    'Image': { width: 100, height: 100, fill: undefined },

    'ParticipantImage': { width: 100, height: 100, image: editorImages.value.participant, fill: undefined },
    'ParticipantQR': { width: 100, height: 100, image: editorImages.value.qr, fill: undefined },
    'ParticipantDegree': { text: '[tytuł naukowy uczestnika]', fill: '#000000', fontSize: 24, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantName': { text: '[imię i nazwisko uczestnika]', fill: '#000000', fontSize: 32, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantFirstName': { text: '[imię uczestnika]', fill: '#000000', fontSize: 32, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantLastName': { text: '[nazwisko uczestnika]', fill: '#000000', fontSize: 32, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantGroup': { text: '[grupa uczestnika]', fill: '#000000', fontSize: 24, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantInstitution': { text: '[instytucja uczestnika]', fill: '#000000', fontSize: 24, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantLicenseNumber': { text: '[numer PWZ]', fill: '#000000', fontSize: 24, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont },
    'ParticipantPoints': { text: '[liczba punktów]', fill: '#000000', fontSize: 24, lineHeight: 1.5, width: 500, align: 'left', fontFamily: defaultFont }
  }

  const templateItemNames: {
    [key in TemplateItemType]: string
  } = {
    'Text': 'Pole tekstowe',
    'Rect': 'Prostokąt',
    'Circle': 'Koło',
    'Image': 'Obraz',

    'ParticipantImage': 'Zdjęcie uczestnika',
    'ParticipantQR': 'Kod QR uczestnika',
    'ParticipantDegree': 'Tytuł naukowy uczestnika',
    'ParticipantName': 'Imię i nazwisko uczestnika',
    'ParticipantFirstName': 'Imię uczestnika',
    'ParticipantLastName': 'Nazwisko uczestnika',
    'ParticipantGroup': 'Grupa uczestnika',
    'ParticipantInstitution': 'Instytucja uczestnika',
    'ParticipantLicenseNumber': 'Numer PWZ',
    'ParticipantPoints': 'Liczba punktów'
  }

  return { sceneWidth, sceneHeight, scaleBy, GUIDELINE_OFFSET, templateItemShapes, defaultConfig, templateItemNames }
}
