/* eslint-disable no-useless-escape */
import '@/plugins/composition-api'

import dayjs from "dayjs";

export default function() {
  const clamp = (num: number, min: number, max: number) =>
    num <= min ? min : num >= max ? max : num;

  const getBase64FromUrl = async (url: string): Promise<string> => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  };

  /**
   * This function is same as PHP's nl2br() with default parameters.
   *
   * @param {string} str Input text
   * @param {boolean} replaceMode Use replace instead of insert
   * @param {boolean} isXhtml Use XHTML
   * @return {string} Filtered text
   */
  function nl2br(
    str: string,
    replaceMode?: boolean,
    isXhtml?: boolean
  ): string {
    const breakTag = isXhtml ? "<br />" : "<br>";
    const replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
    return str
      ? (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
      : "";
  }

  /**
   * This function inverses text from PHP's nl2br() with default parameters.
   *
   * @param {string} str Input text
   * @param {boolean} replaceMode Use replace instead of insert
   * @return {string} Filtered text
   */
  function br2nl(str: string, replaceMode?: boolean): string {
    const replaceStr = replaceMode ? "\n" : "";
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[\/]?>/gi, replaceStr);
  }

  const dates = (start?: dayjs.ConfigType, end?: dayjs.ConfigType) =>
    start && end && dayjs(start).isSame(end, "day")
      ? dayjs(start).format("D MMM YYYY")
      : (start ? dayjs(start).format("D MMM YYYY") : "") +
        (start && end ? " – " : "") +
        (end ? dayjs(end).format("D MMM YYYY") : "");

  return { clamp, getBase64FromUrl, nl2br, br2nl, dates };
}
