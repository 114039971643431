import '@/plugins/composition-api'

import { Ref } from 'vue-demi'
import type { TemplateItem, TemplateItemType } from './editor'
import useEditorFonts from './editorFonts'

export default function (selectedItem: Ref<TemplateItem | undefined>) {
  const { defaultFont, getFonts, getFontWeights } = useEditorFonts()

  const shapeFields: { [key in TemplateItemType]?: string[] } = {
    Text: ['text', 'align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    Rect: ['x', 'y', 'width', 'height', 'rotation', 'cornerRadius', 'fill', 'opacity'],
    Circle: ['x', 'y', 'width', 'height', 'rotation', 'fill', 'opacity'],
    Image: ['image', 'x', 'y', 'width', 'height', 'rotation', 'opacity'],

    ParticipantImage: ['x', 'y', 'width', 'height', 'rotation', 'opacity'],
    ParticipantQR: ['x', 'y', 'width', 'height', 'rotation', 'opacity'],
    ParticipantDegree: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantName: ['align', 'fontFamily', 'fontStyle', 'textDecoration',  'textStyle','fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantFirstName: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantLastName: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantGroup: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantInstitution: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantLicenseNumber: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize'],
    ParticipantPoints: ['align', 'fontFamily', 'fontStyle', 'textDecoration', 'textStyle', 'fontSize', 'lineHeight', 'x', 'y', 'width', 'height', 'rotation', 'opacity', 'fill', 'fixedFontSize']
  }

  const fields: {
    [key in keyof TemplateItem['config']]: {
      default?: any;
      full?: boolean;
      field: { [key: string]: any };
    }
  } = {
    text: { default: '', full: true, field: { name: 'text', label: 'Tekst', type: 'textarea' } },
    fontFamily: { default: defaultFont, full: true, field: { name: 'fontFamily', label: 'Czcionka', type: 'select', mandatory: true, items: getFonts(true) } },
    fontSize: { default: 24, field: { name: 'fontSize', label: 'Rozmiar czcionki', type: 'number', min: 1 } },
    lineHeight: { default: 1, field: { name: 'lineHeight', label: 'Wysokość linii', type: 'number', min: 0, step: 0.01 } },
    fontStyle: { default: '400', full: true, field: { name: 'fontStyle', label: 'Styl czcionki', type: 'select', mandatory: true, items: () => getFontWeights(selectedItem.value?.config.fontFamily, true) } },
    textDecoration: { default: '', full: true, field: { name: 'textDecoration', label: 'Dekoracja tekstu', type: 'select', mandatory: true, items: [{ text: 'Brak', value: '' }, { text: 'Podkreślenie', value: 'underline' }, { text: 'Przekreślenie', value: 'line-through' }] } },
    x: { default: 100, field: { name: 'x', label: 'X', type: 'number' } },
    y: { default: 100, field: { name: 'y', label: 'Y', type: 'number' } },
    width: { default: 100, field: { name: 'width', label: 'Szerokość', type: 'number', min: 1 } },
    height: { default: 100, field: { name: 'height', label: 'Wysokość', type: 'number', min: 1 } },
    rotation: { default: 0, field: { name: 'rotation', label: 'Obrót', type: 'number' } },
    radius: { default: 0, field: { name: 'radius', label: 'Promień', type: 'number', min: 1 } },
    cornerRadius: { default: 0, field: { name: 'cornerRadius', label: 'Zaokrąglenie', type: 'number', min: 0 } },
    fill: { default: '#000000', field: { name: 'fill', label: 'Kolor', type: 'color' } },
    align: { default: 'left', full: true, field: { name: 'align', label: 'Wyrównanie', type: 'select', mandatory: true, items: [{ text: 'Do lewej', value: 'left' }, { text: 'Do środka', value: 'center' }, { text: 'Do prawej', value: 'right' }] } },
    image: { default: undefined, full: true, field: { name: 'image', label: 'Obraz', type: 'image', format: 'base64' } },
    opacity: { default: 1, field: { name: 'opacity', label: 'Przezroczystość', type: 'number', min: 0, max: 1, step: 0.01 } },
    textStyle: { default: '', full: true, field: { name: 'textStyle', label: 'Styl tekstu', type: 'select', mandatory: true, items: [{ text: 'Brak', value: '' }, { text: 'Kapitaliki', value: 'capitalize' }, { text: 'Duże litery', value: 'uppercase' }] } },
    fixedFontSize: { default: false, full: true, field: { name: 'fixedFontSize', label: 'Dopasuj tekst do szerokości', type: 'select', mandatory: true, items: [{ text: 'Nie', value: false }, { text: 'Tak', value: true }] } },
  }

  return { shapeFields, fields }
}
