import '@/plugins/composition-api'

import allFonts from "google-fonts-complete";
import WebFont from "webfontloader";
import { ref } from '@vue/composition-api';
import { createSharedComposable } from '@vueuse/core';

export default createSharedComposable(function() {
  const loadedFonts = ref<Set<string>>(new Set([]))

  const defaultFont = "Roboto";
  const getFonts = (list = false) =>
    list
      ? Object.entries(allFonts).map(([name, data]) => ({
          value: name,
          text: `${name}${data.subsets.includes("latin-ext") ? "" : " ⚠"}`,
        }))
      : Object.keys(allFonts);

  const getFontWeights = (font: string, list = false) => {
    const fonts = allFonts as any;
    if (fonts[font])
      return [
        ...Object.keys(fonts[font]?.variants?.normal ?? {})?.map((wght) =>
          list ? { text: wght, value: wght } : wght
        ),
        ...Object.keys(fonts[font]?.variants?.italic ?? {})?.map((wght) =>
          list
            ? { text: `${wght} pochyła`, value: `italic ${wght}` }
            : `${wght}italic`
        ),
      ];
    return [];
  };

  const loadFonts = (fonts: string[]) =>
    new Promise<void>((resolve) => {
      const families = fonts
        .filter((font) => !loadedFonts.value.has(font))
        .map((font) => `${font}:${getFontWeights(font).join(",")}`);

      if (families?.length) {
        WebFont.load({
          google: { families },
          active: () => {
            fonts.forEach(font => loadedFonts.value.add(font));
            resolve();
          },
        });
      } else {
        resolve();
      }
    });

  return { defaultFont, getFonts, getFontWeights, loadFonts };
})
