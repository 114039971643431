import "@/plugins/composition-api";

import { tryOnMounted, useAsyncState } from "@vueuse/core";
// import { onMounted } from 'vue-demi';
import useHelper from "./helper";

export default function() {
  const { getBase64FromUrl } = useHelper();

  /**
   * Example dynamic data
   */
  const exampleData = {
    short: {
      code: "ABC",
      degree: "dr",
      firstName: "Jan",
      lastName: "Kowalski",
      participantGroups: [{ name: "VIP" }],
      institutionName: "Uniwersytet Jagielloński",
      licenseNumber: "123456",
      educationalPoints: "123",
    },
    medium: {
      code: "ABC123",
      degree: "mgr",
      firstName: "Klaudia",
      lastName: "Tarczewska",
      participantGroups: [{ name: "Wystawca" }],
      institutionName: "Uniwersytet Śląski w Katowicach",
      licenseNumber: "123456",
      educationalPoints: "123",
    },
    long: {
      code: "ABCDEF123456",
      degree: "dr hab.",
      firstName: "Małgorzata",
      lastName: "Witkowska-Konieczna",
      participantGroups: [{ name: "Komitet organizacyjny" }],
      institutionName:
        "Komenda Wojewódzka Państwowej Straży Pożarnej w Katowicach",
      licenseNumber: "123456",
      educationalPoints: "123",
    },
  };

  /**
   * Editor image assets
   */
  const {
    state: editorImages,
    isLoading: editorImagesLoading,
    execute: fetchEditorImages,
  } = useAsyncState<{ [key: string]: string }>(
    async () => ({
      participant: await getBase64FromUrl("/img/placeholder/avatar.jpg"),
      qr: await getBase64FromUrl("/img/placeholder/qr.png"),
      coin: await getBase64FromUrl("/img/placeholder/coin.png"),
      plant: await getBase64FromUrl("/img/placeholder/plant.png"),
      broken: await getBase64FromUrl("/img/placeholder/broken.png"),
    }),
    {
      participant: "/img/placeholder/avatar.jpg",
      qr: "/img/placeholder/qr.png",
      coin: "/img/placeholder/coin.png",
      plant: "/img/placeholder/plant.png",
      broken: "/img/placeholder/broken.png",
    }
  );

  tryOnMounted(fetchEditorImages);

  return { exampleData, editorImages, editorImagesLoading };
}
