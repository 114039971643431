/* eslint-disable no-self-assign */
/* eslint-disable prefer-const */
import "@/plugins/composition-api";

import QRCode from "qrcode";
import Konva from "konva";
import { TemplateItemType } from "./editor";
import useEditorConfig from "./editorConfig";
import useEditorFonts from "./editorFonts";
import useAttachment from "../image";
import { ref, SetupContext } from "@vue/composition-api";
import useEditorSettings from "./editorSettings";

export default function({ root }: Partial<SetupContext>) {
  const { templateItemShapes: shapes } = useEditorConfig();
  const { shapeFields } = useEditorSettings(ref());
  const { loadFonts } = useEditorFonts();
  const { getBase64Image } = useAttachment({ root });

  const render = async (
    template: {
      format: { [key: string]: any };
      items: { [key: string]: any }[];
    },
    participant: { [key: string]: any }
  ) => {
    const { width, height } = template.format as {
      width: number;
      height: number;
    };

    const container = document.createElement("div");
    container.setAttribute("width", width.toString());
    container.setAttribute("height", height.toString());

    const stage = new Konva.Stage({ container, width, height });
    const layer = new Konva.Layer();

    await loadFonts(
      template.items
        .filter((item) => !!item.config.fontFamily)
        .map((item) => item.config.fontFamily)
    );

    for (const item of template.items) {
      const type = item.type as TemplateItemType;
      const config = { ...item.config } as (
        | Konva.ShapeConfig
        | Konva.ImageConfig
        | Konva.TextConfig
      ) &
        Konva.ImageConfig;

      switch (type) {
        case "ParticipantDegree":
          config.text = participant.degree;
          break;
        case "ParticipantFirstName":
          config.text = participant.firstName;
          break;
        case "ParticipantLastName":
          config.text = participant.lastName;
          break;
        case "ParticipantName":
          config.text = `${participant.firstName ??
            ""} ${participant.lastName ?? ""}`;
          break;
        case "ParticipantInstitution":
          config.text = participant.institutionName;
          break;
        case "ParticipantGroup":
          config.text = participant.participantGroups[0]?.name;
          break;
        case "ParticipantLicenseNumber":
          config.text = participant.licenseNumber;
          break;
        case "ParticipantPoints":
          config.text = participant.educationalPoints ?? "0";
          break;
        case "ParticipantImage": {
          await new Promise<void>((resolve) => {
            const image = document.createElement("img");
            image.onload = () => resolve();
            if (participant.image) {
              const id =
                typeof participant.image === "string"
                  ? participant.image
                  : participant.image?.id;
              getBase64Image(id).then(
                (data) => (image.src = data ?? (config.image as any))
              );
            } else {
              image.src = config.image as any;
            }
            config.image = image;
          });
          break;
        }
        case "ParticipantQR": {
          await new Promise<void>((resolve) => {
            const image = document.createElement("img");
            image.onload = () => resolve();

            QRCode.toDataURL(
              participant.code,
              {
                type: "image/png",
                margin: 0,
                color: { light: "#FFFFFF00", dark: "#000000FF" },
              },
              (err, url) => {
                image.src = url as any;
              }
            );

            config.image = image;
          });
          break;
        }
        case "Image": {
          await new Promise<void>((resolve) => {
            const image = document.createElement("img");
            image.onload = () => resolve();
            image.src = config.image as any;
            config.image = image;
          });
        }
      }

      const textShapes = Object.entries(shapeFields)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([shape, fields]) => fields.includes("textStyle"))
        .map(([item]) => item);

      if (textShapes.includes(type) && config.text) {
        switch (config.textStyle) {
          case "uppercase":
            config.text = config.text.toUpperCase();
            break;
          case "capitalize":
            config.text = config.text
              .split(" ")
              .map(
                (item: string) => item.charAt(0).toUpperCase() + item.slice(1)
              )
              .join(" ");
            break;
        }
      }

      if (
        textShapes.includes(type) &&
        config.text &&
        config.width &&
        config.fixedFontSize
      ) {
        const get_tex_width = (txt: string, font: string) => {
          let element: any;
          let context: any;
          element = document.createElement("canvas");
          context = element.getContext("2d");
          if (context) {
            context.font = font;
            return context.measureText(txt).width;
          }
        };
        const textWidth = get_tex_width(
          config.text,
          `${config.fontSize}px ${config.fontFamily}`
        );
        if (textWidth > config.width) {
          config.fontSize = config.fontSize / (textWidth / config.width);
        } else config.fontSize = config.fontSize;
      }

      const shape = new shapes[type](config);
      layer.add(shape);
    }

    stage.add(layer);

    return stage.toDataURL();
  };

  return { render };
}
