










































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { AxiosInstance, Method } from "axios";
import usePrinter from "@/use/templates/printer";
import useTemplate from "@/use/templates/template";
import useEditorData from "@/use/templates/editorData";
import { v4 as uuid } from "uuid";
import { computedAsync } from "@vueuse/core";
import useMisc from "@/use/misc";

export default defineComponent({
  components: {
    OTemplateEditor: () =>
      import("@/components/organisms/template-editor/o-template-editor.vue"),
    OPrintSingle: () =>
      import("@/components/organisms/print/o-print-single.vue"),
  },
  setup(_, { root }) {
    const { render } = useTemplate({ root });
    const { exampleData } = useEditorData();
    const { goBack } = useMisc({ root });
    const {
      connect,
      printers,
      selectPrinter,
      isActive,
      selectedPrinter,
    } = usePrinter();

    const dimensions = [
      {
        text: root.$t("panel.event.modules.receptionConfig.plate"),
        value: JSON.stringify({ width: 2480, height: 3508, margin: 236 }),
      },
    ];

    const model = reactive({ name: "", dimensions: dimensions[0].value });
    const items = ref<any[]>([]);
    const selectedDimensions = computed(() => JSON.parse(model.dimensions));

    const state = reactive({
      isValid: false,
      isEdit: false,
      error: false as boolean | number,
    });

    const id = computed(() => root.$route.params.pid);
    const eventId = computed(() => root.$route.params.id);
    const loading = ref(false);

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      if (id.value) {
        loading.value = true;

        axiosInstance
          .get(`plate-template/${id.value}`)
          .then(({ data }) => {
            model.name = data.name;
            model.dimensions = JSON.stringify(data.format);
            items.value = data.items;
            state.isEdit = true;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    onMounted(fetch);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.misc.templateExist")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const submit = (action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      let method: Method = "POST";
      let url = `event/${eventId.value}/plate-template`;

      if (id.value) {
        method = "PUT";
        url = `plate-template/${id.value}`;
      }

      const headers = {
        event: eventId.value,
      };

      const data = {
        name: model.name,
        format: JSON.parse(model.dimensions),
        items: items.value,
      };

      loading.value = true;

      axiosInstance
        .request({ method, url, data, headers })
        .then(() => {
          if (action === "new") {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc("layout.misc.saveSuccess"),
            });

            model.name = "";
            model.dimensions = "";
            items.value = [];
          } else if (action === "close") {
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "primary",
              message: root.$tc("layout.misc.saveSuccess"),
            });

            goBack("panel.event.view.receptionConfig.plate.list");
          }
        })
        .catch((error) => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
          console.log(error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const exampleDataLength = ref<"short" | "medium" | "long">("short");
    const renderImage = computedAsync(() =>
      render(
        { format: JSON.parse(model.dimensions), items: items.value },
        exampleData[exampleDataLength.value]
      )
    );

    const renderConfig = computed(() => {
      const format = JSON.parse(model.dimensions);

      const density = 300;
      const units = "in";

      const w = format.width / density;
      const h = format.height / density;

      const orientation = w > h ? "landscape" : "portrait";
      const width = orientation === "landscape" ? h : w;
      const height = orientation === "portrait" ? h : w;

      const size = { width, height };

      const jobName = `Tabliczka: ${
        exampleData[exampleDataLength.value].firstName
      } ${exampleData[exampleDataLength.value].lastName} [${uuid()}]`;

      return { orientation, size, density, units, jobName };
    });

    const isPrintDialogOpen = ref(false);

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return {
      items,
      model,
      dimensions,
      state,
      isActive,
      selectedPrinter,
      selectedDimensions,
      exampleDataLength,
      renderImage,
      renderConfig,
      submit,
      connect,
      printers,
      selectPrinter,
      isPrintDialogOpen,
      rules,
    };
  },
});
