/* eslint-disable @typescript-eslint/no-empty-function */
import "@/plugins/composition-api";

import { useLocalStorage, tryOnMounted } from "@vueuse/core";
import { createSharedComposable } from "@vueuse/shared";
import * as qz from "qz-tray";
import { ref } from "@vue/composition-api";

const serializer = {
  read: (v: any) => (v ? JSON.parse(v) : null),
  write: (v: any) => JSON.stringify(v),
};

export interface PrinterSettings {
  host: string;
  usingSecure: boolean;
}

export default createSharedComposable(function() {
  const isLoadingPrinters = ref(false);
  const isLoading = ref(false);
  const isOpen = ref(false);

  const open = () => (isOpen.value = true);
  const close = () => (isOpen.value = false);
  const toggle = () => (isOpen.value ? close() : open());

  const isActive = ref<boolean>();

  const settings = useLocalStorage<PrinterSettings>(
    "printer-settings",
    {
      host: "localhost",
      usingSecure: true,
    },
    { serializer }
  );

  /**
   * printers
   */

  const printers = ref<any[]>([]);
  const printerStatus = ref<{ [key: string]: any }>({});
  const jobStatus = ref<{ [key: string]: any }>({});
  const selectedPrinter = useLocalStorage<string>("selected-printer", null);

  const printerCallback = (eventData: { [key: string]: any }) => {
    printerStatus.value = {
      ...printerStatus.value,
      ...(eventData.eventType === "PRINTER"
        ? { [eventData.printerName]: eventData }
        : {}),
    };

    jobStatus.value = {
      ...jobStatus.value,
      ...(eventData.eventType === "JOB"
        ? { [eventData.jobName]: eventData }
        : {}),
    };
  };

  tryOnMounted(() => qz.printers.setPrinterCallbacks(printerCallback));

  const updatePrinterStatus = () => {
    if (isActive.value) {
      isLoadingPrinters.value = true;

      qz.printers
        .getStatus()
        .then((data: any) => {
          if (data && data.printerName) {
            printerStatus.value = {
              ...printerStatus.value,
              [data.printerName]: data,
            };
          }
        })
        .catch(() => {})
        .finally(() => {
          isLoadingPrinters.value = false;
        });
    } else {
      printerStatus.value = {};
    }
  };

  const updatePrinters = () => {
    if (isActive.value) {
      isLoadingPrinters.value = true;

      qz.printers
        .details()
        .then((data: any[]) => (printers.value = data))
        .catch(() => (printers.value = []))
        .finally(() => {
          isLoadingPrinters.value = false;
        });
    } else {
      printers.value = [];
    }
  };

  const selectPrinter = (name: string) => {
    qz.configs.create(name);
    (selectedPrinter as any).value = name;
  };

  /**
   * connection
   */

  const errorCallback = (event: Event) => {
    console.log(event);
  };

  tryOnMounted(() => qz.websocket.setErrorCallbacks(errorCallback));

  const updateStatus = () => {
    isActive.value = qz.websocket.isActive();
  };

  tryOnMounted(updateStatus);

  const update = () => {
    updateStatus();
    updatePrinters();
    updatePrinterStatus();
  };

  const connect = () => {
    const options = {
      host: [(settings as any).value.host, "localhost.qz.io"],
      port: {
        secure: [8181, 8282, 8383, 8484],
        insecure: [8182, 8283, 8384, 8485],
      },
      usingSecure: (settings as any).value.usingSecure,
      keepAlive: 60,
      retries: 0,
      delay: 0,
    };

    isLoading.value = true;

    qz.websocket
      .connect(options)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        qz.printers
          .startListening()
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            isLoading.value = false;
            update();
          });
      });
  };

  const disconnect = () => {
    isLoading.value = true;

    qz.printers
      .stopListening()
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        qz.websocket
          .disconnect()
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            isLoading.value = false;
            update();
          });
      });
  };

  const printImage = (
    data: string,
    config?: { [key: string]: any }
  ): Promise<any> => {
    return qz.print(qz.configs.create((selectedPrinter as any).value, config), [
      {
        type: "pixel",
        format: "image",
        flavor: "base64",
        data,
      },
    ]);
  };

  return {
    ...{ isLoadingPrinters, isLoading, isOpen },
    ...{ open, close, toggle },
    ...{ settings },
    ...{
      printers,
      printerStatus,
      jobStatus,
      selectedPrinter,
      updatePrinters,
      selectPrinter,
    },
    ...{ update, updatePrinters },
    ...{ isActive, connect, disconnect },
    ...{ printImage },
  };
});
